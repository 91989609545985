<template>
    <div class="dep-wrap">

        <section
                :draggable="item.id != 0"
                @dragstart="dragStart($event)"
                @dragend="dragEnd($event)"
                @drag="dragging($event)"
                @dragenter="dragEnter($event)"
                @dragleave="dragLeave($event)"
                @drop="drop($event)"
                @dragover="dragOver($event)"
                :class="solveClass()" :data-level="level" :id="'structure-item-' + item.id"
                 class="structure-item  structure-dept-editable ">
            <div class="structure-dept-title">
                <div class="structure-dept-title-text ">
                    <b-link class="department-name " @click.prevent="consoleLog('here',level);$emit('edit-item',item)"
                            v-b-tooltip.hover.top.d999="item.name">{{item.name}}
                    </b-link>
                    <!--<feather-icon v-if="dataStructureLevel == 1 && level == 1" icon="Edit2Icon"></feather-icon>-->
                </div>
                <div class="structure-icon-box">

                </div>
            </div>
            <div class="structure-boss-block">
                <p class="leader-block-info">{{$t('label_branch_leader')}}: <span
                        :title="item.leader? item.leader.name : ''" class="leader-data">{{item.leader? item.leader.name : ''}}</span>
                </p>
            </div>
            <div class="structure-employee-block">

                <div class="structure-employee-title">
                    {{$t('label_employees')}}
                </div>

                <template v-if="item.users && item.users.length > 0">
                    <div class="structure-employee-list">

                        <b-avatar-group
                                class="" style="padding-top: 5px;"
                                size="26px"
                        >

                            <b-avatar :style="{backgroundColor: getNameColor(user)}"
                                      v-for="user in item.users.slice(0,8)"
                                      :key="user.id"
                                      v-b-tooltip.hover.bottom="user.name"
                                      :text="!user.profile_picture? getNameLetters(user) :''"
                                      :src="user.profile_picture? $domain + user.profile_picture : ''"
                                      class="pull-up"
                                      button
                                      @click.prevent="resolveRouteToRedirectUser(user);"
                            />

                            <h6 v-if="item.users.length > 8" class="align-self-center cursor-pointer ml-1 mb-0">
                                <a href="javascript:void(0)" :id="'user_level_'+level+'_' + item.id"
                                   data-popover-dismiss class="structure-more-employee">+{{item.users.length - 8 }}</a>
                            </h6>
                            <b-popover
                                    class="bg-info"
                                    :target="'user_level_'+level+'_' + item.id"
                                    triggers="hover"
                                    placement="top"
                                    variant="secondary"
                            >
                                <template #title>
                                    <span>{{$t('label_show')}}</span>
                                </template>
                                <div class="tooltip-content">
                                    <div class="popover-content">
                                        <div v-for="u in item.users" class="structure-employee-wrap">
                                            <a v-if="[1].includes(u.userType)" href="javascript:void(0)"
                                               target="_blank">{{u.name}}</a>
                                            <a v-else href="#" @click="resolveRouteToRedirectUser(u)">{{u.name}}</a>
                                        </div>
                                    </div>
                                </div>
                            </b-popover>
                        </b-avatar-group>

                    </div>

                </template>

                <span v-else class="ml-1">&nbsp;</span>
            </div>

            <template v-if="level == 3 && notShownCount > 0">
                <div class="show-nested-wrap">
                    <div @click="showNested(item.id)" class="structure-show-nested">+<span
                            class="structure-nested-count">{{notShownCount}}</span> {{$t('label_show')}}
                    </div>
                </div>

                <div v-show="needShowNested" :data-structure-level="parseInt(dataStructureLevel) + 1"
                     :style="{'z-index':dataStructureLevel}"
                     class="structure-overflow">
                    <div @click="hideNested()" class="close-structure-overflow">x</div>
                    <department-lvl
                            @item-edited="$emit('item-edited', {})"
                            :structure-data="preparedStructureData"
                                    :data-structure-level="parseInt(dataStructureLevel) + 1"></department-lvl>
                </div>
            </template>

        </section>

        <div v-if="level == 2 && thirdLvlStructure" class="sub-structure-parent">
            <department v-for="subItem, subInd in getThirdLvlItems(item.id)"
                        :per-first-element="false"
                        :key="subItem.id"
                        :item="subItem"
                        @edit-item="$emit('edit-item',subItem)"
                        @item-edited="$emit('item-edited', {})"
                        :ind="subInd"
                        :level="parseInt(level) + 1"
                        :not-shown-count="forthLvlStructure ? forthLvlStructure.findAll('parent_id', subItem.id).length : 0"
                        :data-structure-level="dataStructureLevel"
            ></department>

        </div>

    </div>
</template>

<script>
    import {BPopover, BAvatar, BLink, BMedia, BMediaBody, BMediaAside, BAvatarGroup, VBTooltip} from 'bootstrap-vue'
    import {MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './../moduleHelper'
    import {resolveRouteToRedirectUser} from '../../pagesHelper'
    import departmentLvl from './structureLvl'

    export default {

        name: "department",

        props: [
            'item',
            'level',
            'perFirstElement',
            'ind',
            'thirdLvlStructure',
            'forthLvlStructure',
            'dataStructureLevel',
            'notShownCount'
        ],

        components: {
            BPopover, BAvatar, BLink, BMedia, BMediaBody, BAvatarGroup, BMediaAside,
            departmentLvl,
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,
                resolveRouteToRedirectUser,
                needShowNested: false,
                preparedStructureData: [],
                enteredElsCounter: 0
            }
        },

        methods: {
            solveClass() {
                let itemClass = '';
                if (this.level == 1) {
                    itemClass = 'bx-middle-connect';
                } else if (this.level == 2) {
                    if (this.perFirstElement == 0) return '';
                    if (this.ind < this.perFirstElement) {
                        if (this.ind + 1 == this.perFirstElement) {
                            itemClass = 'bx-left-connect';
                        } else {
                            itemClass = 'bx-left-connect-long';
                        }
                    } else {
                        if (this.ind + 1 == this.perFirstElement + 1) {
                            itemClass = 'bx-right-connect';
                        } else {
                            itemClass = 'bx-right-connect-long';
                        }
                    }

                } else if (this.level == 3) {
                    if (this.ind == 0) {
                        itemClass = 'bx-vert-connect';
                    } else {
                        itemClass = 'bx-vert-connect-long';
                    }
                }
                return itemClass;
            },

            getThirdLvlItems(parent_id) {
                return this.thirdLvlStructure.findAll('parent_id', parent_id);
            },

            showNested(id) {

                let startInd = ((parseInt(this.level) - 1) * this.dataStructureLevel);

                let slice = this.$store.state[this.MODULE_PREFIX].structure_data["lvl_" + startInd];

                if (slice) {

                    let parenItem = slice.find(dep => dep.id == id);

                    if (parenItem) {
                        let subItems = this.$store.state[this.MODULE_PREFIX].structure_data["lvl_" + (startInd + 1)].filter(function (val) {
                            return val.parent_id == id;
                        });

                        let structureData = {
                            '0': [parenItem],
                            '1': subItems,
                            '2': this.$store.state[this.MODULE_PREFIX].structure_data["lvl_" + (startInd + 2)],
                            '3': this.$store.state[this.MODULE_PREFIX].structure_data["lvl_" + (startInd + 3)]
                        };

                        this.needShowNested = true;
                        this.preparedStructureData = structureData;

                    }

                }

            },

            hideNested() {
                this.needShowNested = false;
            },

            getNameLetters(user) {
                // console.log('u',user);
                return user.firstName[0].ucFirst() + user.lastName[0].ucFirst();
            },
            getNameColor(user) {
                let charCode = user.firstName.charCodeAt(0) * 15000;
                return this.decimalColorToHTMLcolor(charCode)+ ' !important';
            },
            decimalColorToHTMLcolor(number) {
                //converts to a integer
                let intnumber = number - 0;

                // isolate the colors - really not necessary
                let red, green, blue;

                // needed since toString does not zero fill on left
                let template = "#000000";

                // in the MS Windows world RGB colors
                // are 0xBBGGRR because of the way Intel chips store bytes
                red = (intnumber & 0x0000ff) << 16;
                green = intnumber & 0x00ff00;
                blue = (intnumber & 0xff0000) >>> 16;

                // mask out each color and reverse the order
                intnumber = red | green | blue;

                // toString converts a number to a hexstring
                let HTMLcolor = intnumber.toString(16);

                //template adds # for standard HTML #RRGGBB
                HTMLcolor = template.substring(0, 7 - HTMLcolor.length) + HTMLcolor;

                return HTMLcolor;
            },
            dragEnd(e){
                e.stopPropagation();
                // console.log('e.dataTransfer',e.dataTransfer);
                // console.log('e.dataTransfer.setData("application/item-id", String(elId));');
                let els = document.getElementsByClassName('dragging-department');

                for(let i = 0; i< els.length; i++) {
                    els[i].remove();
                }

            },
            dragStart(e)  {
                e.stopPropagation();
                let target = e.currentTarget;
                // if(target.dataset['level'] == 1){
                if(target.id == 1){
                    return false;
                }
                // let elId = new Date().getTime();
                let wrap = target.closest(".dep-wrap");

                e.dataTransfer.effectAllowed = "move";
                e.dataTransfer.dropEffect = 'move';
                let copy = wrap.cloneNode(true);

                copy.classList.add('dragging-department');
                copy.style.position = 'fixed';
                copy.style.width = wrap.offsetWidth;
                copy.style.height = wrap.offsetHeight;
                copy.style.zIndex = 10000;
                copy.style.opacity = 0;
                copy.style.visibility = 0;
                // copy.id = elId;
                wrap.after(copy);
// console.log('target.id',target.id);
                e.dataTransfer.setDragImage(new Image(), 0, 0);//to hide standarв block, that we will see when drag
                // e.dataTransfer.setDragImage(copy, e.pageX + 'px', e.pageY + 'px');
                e.dataTransfer.setData('text/plain', String(target.id)); //getData works only in drop function

            },
            dragging(e) {
                e.stopPropagation();
                let movedCopy = document.querySelector('.dragging-department');
                movedCopy.style.left = e.pageX + 7 + 'px'; //if cursor is under block it cannot work, need shift it
                movedCopy.style.top = e.pageY + 0 + 'px';
                movedCopy.style.opacity = 1;
                movedCopy.style.visibility = 1;
                // e.dataTransfer.getData("application/item-id")// will not work for secure reasons
            },
            dragEnter(e){
                e.stopPropagation();
                // console.log('dragEnter');
                this.enteredElsCounter++;
                e.preventDefault(); // needed for IE
                let wrap = document.getElementById('structure-draggable').querySelector('.dragging-department');
                let activeElement = wrap.querySelector('section');

                let currentElement = e.currentTarget;

                if(activeElement.id !== currentElement.id) {
                    currentElement.classList.add('dropping-department');
                }
            },
            dragOver(e){
                e.stopPropagation();
                e.preventDefault();// to allow drop
            },
            dragLeave(e){
                e.stopPropagation();
                this.enteredElsCounter--;
                let eventHandlerElement = e.currentTarget;

                if(this.enteredElsCounter == 0) {
                    eventHandlerElement.classList.remove('dropping-department');
                }
            },
            drop(e){
                e.stopPropagation();
                let eventHandlerElement = e.currentTarget;
                eventHandlerElement.classList.remove('dropping-department');
                let elSource = document.getElementById(e.dataTransfer.getData('text/plain'));
                let elIdSource = elSource.id.replace('structure-item-', '');
                let parentId = eventHandlerElement.id.replace('structure-item-', '');
                let lvlSource = elSource.getAttribute('data-level');
                let structureLvlSource = elSource.closest('[data-structure-level]').getAttribute('data-structure-level');
                let lvlTarget = eventHandlerElement.getAttribute('data-level');
                let structureLvlTarget = eventHandlerElement.closest('[data-structure-level]').getAttribute('data-structure-level');
                // console.log('structureLvlSource', structureLvlSource,structureLvlTarget);
                // console.log('lvl2', lvlTarget);
                if(structureLvlSource < structureLvlTarget || (structureLvlSource == structureLvlTarget && lvlSource < lvlTarget)) {
                    let sourceParent = elSource.closest('.dep-wrap');
                    let targetItemIsInTheSameTree = sourceParent.querySelector('#'+eventHandlerElement.id);
                    // console.log('sourceParent',sourceParent,eventHandlerElement.id, targetItemIsInTheSameTree);
                    if(targetItemIsInTheSameTree){
                        // alert('the same tree');
                        this.showToast('error', this.$t('label_forbidden'));
                        return false;
                    }
                    // let targetParent = eventHandlerElement.closest(sourceParent);
                    // console.log(sourceParent,targetParent);
                }
                if(elIdSource == parentId){
                    // this.showToast('error', this.$t('label_forbidden'));
                    return false;
                }
                // console.log(e.dataTransfer.dropEffect,'t', elIdSource,parentId);
                this.async('put', '/departments/parent/' + elIdSource, {parent_id:parentId}, function (resp) {
                    this.$emit('item-edited', {});
                    // this.$bvModal.hide(this.PREFIX + '-modal');
                });
            },
        }

    }
</script>