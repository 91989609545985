
export default {
  namespaced: true,

  state: {

      structure_data:[],

  },

}

